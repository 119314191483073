<template>
    <div id="wlop" class="wlop">
        <Steps :steps="processSteps" :nbrProducts="nbrProducts" :active="2" />
        <div>
            <div class="transaction-infos">
                <span class="store">{{store}} 2</span>
                <span class="total-amount">{{$t("sipsTransactionAmount", {totalAmount: totalAmount.toFixed(2)})}}</span>
            </div>
            <!-- <button @click="submitForm">submit</button> -->
            
            <div class="credit-card-form">
                <span class="indications" @click="fillFakeData()">{{$t("sipsFormTitle")}}</span>
                <span v-if="showError" class="indications error">{{$t("sipsError")}}</span>

                <div id="div-hosted-tokenization" class="wlop-form"></div> 
                              
                <div class="actions-wrapper">                   
                    <b-btn class="custom-btn outlined" @click="$router.push('/paymentMethods')">{{$t("cancel")}}</b-btn>
                    <b-btn class="custom-btn" @click="submitForm">{{$t("validate")}}</b-btn>
                </div>
            </div>

            <div class="payment-footer">
                <span class="text">{{$t("sipsFooterExplain")}}</span>
                <div class="payment-logos">
                    <div class="logo" :style="{backgroundImage: `url(${require('@/assets/img/cb.jpg')})`}"></div>
                    <div class="logo" :style="{backgroundImage: `url(${require('@/assets/img/verified_visa.png')})`}"></div>
                    <div class="logo" :style="{backgroundImage: `url(${require('@/assets/img/mastercard.png')})`}"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    var md5 = require('md5');
    import {getConfig, getSteps} from "@/assets/js/Utils"
    import Steps from "@/components/Food/Steps";
    var checkout = require("@/assets/js/Checkout");
    export default {
        name: "Wlop",
        components: {
            Steps
        },
        data: function() {
            return {
                cardNumber: "",
                expirationYear : "",
                expirationMonth : "",
                cvv: "",
                showLoader : true,
                totalAmount : 0,
                collapseTransactionInfos: true,
                loaderContent : "You will be redirected to the payment page...",
                showContent : false,
                errorMessage : "",
                processSteps: getSteps(),
                inputValidations:{
                    cardNumber: true,
                    expirationYear : true,
                    expirationMonth : true,
                    cvv: true,
                },
                store: "Worldline Shop",
                currentDate : new Date(),
                page: {
                    title: this.$t("pages.payment.title"),
                    description: null,
                    showBackBtn: true,
                    showFilterBtn: false,
                    showSearch: false
                },
                tokenizer: null
            }
        },
        created(){
            this.totalAmount = this.$parent.basket.totalAmount    
            this.initializeTokenizer();        
        },
        mounted() {
            
        },
        methods : {
            submitForm() {
                if (this.tokenizer) {
                    this.tokenizer.useCardholderName("UserDemo");
                    this.tokenizer.submitTokenization().then((result) => {
                        if (result.success == true) {
                            console.log(result);
                            this.$router.push({ path: 'ticket' })                            
                        } else {
                            console.log(result.error.message);
                        }
                    });
                }
            },
            async fetchTokenizationUrl() {
                try {
                    // const response = await fetch('http://localhost:9090/payment/wlop/init');
                    const response = await fetch(`${getConfig().url_ws}/payment/wlop/init`);
                    const hostedTokenizationUrl = await response.text();
                    return hostedTokenizationUrl;
                } catch (error) {
                    console.error('Erreur lors de la récupération de l\'URL de tokenisation:', error);
                    return null;
                }
            },
            async initializeTokenizer() {
                const hostedTokenizationUrl = await this.fetchTokenizationUrl();
                if (hostedTokenizationUrl) {
                
                    this.tokenizer = new Tokenizer(hostedTokenizationUrl, 'div-hosted-tokenization', { hideCardholderName: true });                    
                    this.tokenizer.initialize().then(() => {
                        // Faire des actions après l'initialisation, si nécessaire
                    }).catch((reason) => {
                        // Gérer les erreurs de chargement de l'iFrame
                    });
                }
            },
            getContext : function() {
                var contextId = this.merchantId+Date.now();
                return contextId;
            },
            // sendTransaction: function() {
            //     if(this.validInputs()){
            //       this.updateLoyalty()
            //     }
            // },
            validInputs(){
                this.inputValidations.cardNumber = this.cardNumber !== "" && this.cardNumber.length == 19

                this.inputValidations.expirationYear = this.expirationYear !== "" && this.expirationYear.length == 4 && parseInt(this.expirationYear) > this.currentDate.getFullYear()

                this.inputValidations.expirationMonth = this.expirationMonth !== "" && this.expirationMonth.length == 2 && parseInt(this.expirationMonth) > 0 && parseInt(this.expirationMonth) <= 12

                this.inputValidations.cvv = this.cvv !== "" && this.cvv.length == 3

                return Object.values(this.inputValidations).reduce((acc, value) => acc && value, true)
            },
            // getCardNumberHash() {
            //     console.log(this.cardNumber);
            //     if(this.cardNumber.split(" ").join("") != "4200000000000000"){
            //         let cardArray = this.cardNumber.split(" ");
            //         cardArray[1] = cardArray[1][0] + cardArray[1][1] + "00";
            //         cardArray[2] = "0000";
            //         return md5(cardArray.join(""))
            //     }
            //     return null
            // },
            // updateLoyalty(){
            //     let hash = this.getCardNumberHash()
            //     if(isNotNull(hash)){
            //         this.$router.push({ path: 'ticket', query: { h: hash } })
            //     }else{
            //         this.$router.push({ path: 'ticket' })
            //     }
            // },
            fillFakeData(){
              this.cardNumber = "4200000000000000"
              this.expirationYear = "2024"
              this.expirationMonth = "12"
              this.cvv = "123"
            }
        },
        computed:{
            showError(){
                return !Object.values(this.inputValidations).reduce((acc, value) => acc && value, true)
            },
            nbrProducts(){
                return this.$parent.basket.basketProducts.reduce((acc, product) => {return acc + product.qty}, 0)
            }
        },
        watch:{
            expirationMonth(newValue){
                if(newValue.length == 2){
                    //this.$refs.expiryYear.focus()
                }
            }
        }
    }
</script>
<style scoped>
    .wlop-form{
        width: 100%;
    }
</style>
